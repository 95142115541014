import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HeaderSession from './components/HeaderSession/HeaderSession';
import CashTodaySession from './components/CashTodaySession/CashTodaySession.js';
import BenefitsSession from './components/BenefitsSession/BenefitsSession.js';
import ServicesSession from './components/ServicesSession/ServicesSession.js';
import FooterSession from './components/FooterSession/FooterSession.js';
import HelpPage from './components/Help/Help.js'; // Importe a nova página
import BannerSession from './components/BannerSession/BannerSession'; // Importe o novo componente

function App() {
  return (
    <Router>
      <HeaderSession />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ajuda" element={<HelpPage />} />
        </Routes>
      <FooterSession />
    </Router>
  );
}

// Componente Home que agrupa as seções da Home
const Home = () => (
  <div>
    <BannerSession />
    <CashTodaySession />
    <BenefitsSession />
    <ServicesSession />
  </div>
);

export default App;
