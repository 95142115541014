import { Col, Container, Row } from 'react-bootstrap';
import IconFacebook from '../../assets/images/footer/facebook-1.png';
import IconLinkedin from '../../assets/images/footer/linkedin-1.png';
import IconInstagram from '../../assets/images/footer/instagram-1.png';
import IconYoutube from '../../assets/images/footer/youtube-1.png';

import ProsegurCashIcon from '../../assets/images/footer/prosegur-cash.png';

import AppleStoreIcon from '../../assets/images/footer/appstore.png';
import PlayStoreIcon from '../../assets/images/footer/googleplay.png';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function FooterSession() {

  const redirectToAppStore = () => {
    window.open('https://apps.apple.com/br/app/prosegur-digital/id6477792895', '_blank');
  };

  const redirectToPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.prosegurdigital&hl=pt_BR', '_blank');
  };

  return (
    <div>
      <footer className="bg-dark text-white pt-5 text-decoration-none">
        <Container>
          <Row>
            {/* Sobre nós */}
            <Col lg="4">
              <h5>Quem somos</h5>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://www.prosegur.com.br/grupo-prosegur"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    O Grupo Prosegur
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.prosegur.com.br/sobre-prosegur"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    Sobre Prosegur
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.prosegur.com.br/sobre-prosegur/onde-estamos"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    Onde estamos
                  </a>
                </li>
              </ul>

              <Col className="mt-5">
                <Row>
                    <img
                      src={AppleStoreIcon}
                      alt="Ícone 5"
                      className="img-fluid mb-3"
                      style={{ width: '150px' }}
                      onClick={redirectToAppStore}
                    />
                </Row>
                <Row>
                    <img
                      src={PlayStoreIcon}
                      alt="Ícone 5"
                      className="img-fluid mb-3"
                      style={{ width: '150px' }}
                      onClick={redirectToPlayStore}
                    />
                </Row>
              </Col>
            </Col>

            {/* Atendimento */}
            <Col lg="4">
              <h5>Nossos Produtos</h5>
              <ul className="list-unstyled">
                <li>
                  <a
                    href=""
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    Conta Digital PJ
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.prosegur.com.br/pequenos-medios-negocios/deposito-diario"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    Depósito Diário Conta Digital
                  </a>
                </li>
              </ul>
           
            </Col>

            {/* Redes sociais */}
            <Col lg="">
              <h5>Fale com a Prosegur Digital</h5>
              <Row>
                <div className="text-white">
                  SAC: 0800 237 1321
                </div>
              </Row>

              <Row>
                <div className="text-white">
                  Deficiência Auditiva: 0800 237 1317
                </div>
              </Row>

              <Row>
                <a
                  href="https://www.prosegur.com.br/canal-etico"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Canal Ético
                </a>
              </Row>

              <Row>
                <a
                  href="https://www.prosegur.com.br/trabalhe-conosco"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Trabalhe conosco
                </a>
              </Row>

              <Row>
                <a
                  href="mailto:suporte.contadigital@prosegur.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Fale Conosco
                </a>
              </Row>

              <Row className="mt-3">
                <div className="d-flex">
                  <img
                    src={ProsegurCashIcon}
                    alt="Nova solução Cash Today"
                    className="img-fluid mb-3"
                    style={{ width: '250px' }}
                  />
                </div>
              </Row>
            </Col>
          </Row>

          {/* Copyright */}
          <Row className="mt-5">
            <Col className="d-flex mb-3 align-items-center flex-wrap">
              <p className="mb-0 me-4">© Copyright 2021 Prosegur Digital</p>
              <div
                className="d-flex align-items-center flex-wrap"
                style={{ gap: '20px' }}
              >
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Política de privacidade
                </a>
                <a
                  href="https://www.prosegur.com.br/legal"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Legal
                </a>
                <a
                  href="https://www.prosegur.com.br/cookies"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Política de cookies
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none"
                >
                  Termos de uso
                </a>
                <a
                  href="https://www.facebook.com/prosegur.brasil"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconFacebook} alt="Facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/prosegur"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconLinkedin} alt="LinkedIn" />
                </a>
                <a
                  href="https://www.instagram.com/prosegurbr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconInstagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/user/prosegur"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IconYoutube} alt="YouTube" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}
