import React, { useState } from 'react';
import { Collapse, Card, Button, Container } from 'react-bootstrap';

const faqs = [
  { question: 'Pergunta 1', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
  { question: 'Pergunta 2', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
  { question: 'Pergunta 3', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
];

const HelpPage = () => {
  return (
    <Container className="py-5">
      <h1 className='mb-3'>Perguntas Frequentes:</h1>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Container>
  );
};

const FaqItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Button
          variant="link"
          onClick={() => setOpen(!open)}
          aria-controls="faq-answer"
          aria-expanded={open}
          className="text-dark fw-bold text-decoration-none"
        >
          {question}
        </Button>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body id="faq-answer">
          {answer}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default HelpPage;
