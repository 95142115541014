import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import Banner from '../../assets/images/header/banner.jpg'; // ajuste o caminho conforme necessário

const BannerSession = () => {
  return (
    <section id="banners">
      <Carousel indicators={false} interval={null} controls={false}>
        <Carousel.Item>
          <div
            className="d-flex"
            style={{
              backgroundImage: `url(${Banner})`,
              height: '100vh',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Container className="d-flex align-items-center">
              <Row className="w-100">
                <Col lg="6" className="text-white">
                  <h1 className="display-3 fw-bold">
                    <strong>INOVADOR</strong>, <br />
                    <strong>SIMPLES & </strong>
                    <br /> <strong>SEGURO</strong>
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default BannerSession;
